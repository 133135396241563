<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <ul class="nav nav-pills nav-stacked affix" >
                <li class="cdi_tab" :class="{'cdi_tab active': 'details' === view }">
                    <a class="pointer" @click="setView('details')">Details</a>
                </li>
                <li class="cdi_tab" :class="{'cdi_tab active': 'password' === view }">
                    <a class="pointer" @click="setView('password')">Password</a>
                </li>

            </ul>
        </nav>
    </div>
</template>

<script>

export default {
    name: 'Nav',
    data() {
        return {
            view: ""
        }
    },
    methods: {
        setView(view) {
            this.$emit('setView', view);
            this.view = view;

        }
    }
}

</script>

<style scoped>

</style>